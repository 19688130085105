<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <!-- <el-button type="primary">导出数据</el-button> -->
            </div>
            <div style="margin-left: auto">

            </div>
        </div>
        <commonTable ref="refTable" :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="buildName" align="center" label="所属楼宇" sortable show-overflow-tooltip />
                <el-table-column prop="number" align="center" label="房屋/单元" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseName }}/{{ scope.row.number }}单元
                    </template>
                </el-table-column>
                <el-table-column prop="houseTypeName" align="center" label="房屋类型" show-overflow-tooltip />
                <el-table-column prop="houseArea" align="center" label="房屋面积" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseArea }}m²
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" @click="hrefFun(scope.row)">查看会议室预约</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getHouseManageList, getPropertySubscribeList } from "@/api/propertyServices";
export default {
    name: "meetingTable",
    components: {
        commonTable,
    },
    data() {
        return {
            parkId: 0,
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            houseTypeData: {
                0: "住房", 1: "办公室", 2: "商铺", 3: "厂房"
            }
        };
    },
    filters: {

    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.currentPage = 1
            this.getList();
        }
    },
    async created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    methods: {
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        hrefFun(row) {
            this.$router.push({
                path: "/meetingUser?houseId=" + row.houseId
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId,
                isSubscribe: 1,
                houseName: this.searchData.name
            };
            try {
                this.loading = true;
                let res = await getHouseManageList(params);
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.houseTypeName = this.houseTypeData[row.houseType];
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>